export class AppConstants {
  static readonly apiBaseUrl: string = 'https://api.trueshare.club';
  // static readonly apiBaseUrl: string = 'http://localhost:3000'
  static readonly storageUrl: string = 'https://storage.trueshare.club';
  static readonly columnBaseUrl: string = 'assets/columns';
}

export class RouteConstants {
  static readonly AUTH_MAIN_ROUTE = 'auth';
  static readonly DASHBOARD_ROUTE = 'dashboard';
  static readonly STRATEGY_ROUTE = 'strategy';
  static readonly STRATEGY_CALLS_ROUTE = 'calls';
  static readonly LOGIN_ROUTE = 'login';
  static readonly AUTH_LOGIN_ROUTE = `${this.AUTH_MAIN_ROUTE}/${this.LOGIN_ROUTE}`;
  static readonly STRATEGY_CALL_DETAIL_ROUTE = `${this.STRATEGY_ROUTE}/${this.STRATEGY_CALLS_ROUTE}`;

  static readonly LINK_DASHBOARD = `/${this.DASHBOARD_ROUTE}`;
  static readonly LINK_LOGIN = `/${this.AUTH_LOGIN_ROUTE}`;
  static readonly LINK_STRATEGY_CALL = `/${this.STRATEGY_CALL_DETAIL_ROUTE}`;
  static readonly LINK_STRATEGY_DETAIL = `/${this.STRATEGY_ROUTE}`;
}

export class StoragePathConstants {
  static readonly avatorUrl = `${AppConstants.storageUrl}/uploads/user`;
  static readonly scripLogoUrl = `${AppConstants.storageUrl}/uploads/scrips`;
  static readonly groupLogoUrl = `${AppConstants.storageUrl}/uploads/groups/logo`;
  static readonly callCardStatusUrl = `${AppConstants.storageUrl}/uploads/calls`;
}

export class PatternConstants {
  static readonly PASSWORD_REGEX = /^([A-z0-9\._@\$]){6,12}$/;
  static readonly MOBILE_REGEX = /^(6|7|8|9)([0-9]){9}$/;

}
