import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DateTime } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storeDataSubjects: Map<string, BehaviorSubject<any>> = new Map();

  constructor() {
    const skeys = Object.keys(localStorage);
    skeys.forEach(k => {
      const data = JSON.parse(localStorage[k]);
      if (data) {
        this.storeDataSubjects.set(k, new BehaviorSubject<any>(data));
      }
    });
  }
  watch(key: string): Observable<any> {
    if (!this.storeDataSubjects.has(key)) {
      this.storeDataSubjects.set(key, new BehaviorSubject<any>(null));
    }
    let item: any = localStorage.getItem(key);
    let storeDataKey = this.storeDataSubjects.get(key);
    if (item == 'undefined') {
      item = undefined;
    } else {
      item = JSON.parse(item);
    }

    if (storeDataKey) {
      storeDataKey.next(item);
    }
    return storeDataKey ? storeDataKey.asObservable() : of(key);
  }

  set(key: string, value: any): void {
    let val = JSON.stringify(value);
    localStorage.setItem(key, val);
    let storeDataKey = this.storeDataSubjects.get(key);
    if (!this.storeDataSubjects.has(key) || !storeDataKey) {
      this.storeDataSubjects.set(key, new BehaviorSubject<any>(val));
    } else {
      storeDataKey.next(value);
    }
  }

  get(key: string): any {
    let valKey: any = localStorage.getItem(key);
    return JSON.parse(valKey);
  }

  remove(key: string): void {
    localStorage.removeItem(key);
    let storeDataKey = this.storeDataSubjects.get(key);
    if (!this.storeDataSubjects.has(key) || !storeDataKey) {
      this.storeDataSubjects.set(key, new BehaviorSubject<any>(null));
    } else {
      storeDataKey.next(null);
    }

  }

  getKeyList(search: string): string[] {
    const arr = Array.from(this.storeDataSubjects.keys());
    return arr.filter(x => x.includes(search));
  }

  saveUserData(data: any) {
    this.set('user', data);
    this.set('token', data.accessToken);
    this.set('lastAuth', new Date().valueOf());
  }

  clearUserData() {
    this.remove('user');
    this.remove('token');
    this.remove('lastAuth');
    this.remove('entitlements');
  }

  getUserData() {
    return this.get('user');
  }

  getToken() {
    return this.get('token') || '';
  }

  isValidUser() {
    let defaultDate = DateTime.fromFormat('1970-01-01', 'yyyy-MM-dd')
    let currentDate = DateTime.now();
    let lastAuthDate = DateTime.fromMillis(Number(this.get('lastAuth') || defaultDate.toMillis()));
    return Math.abs(currentDate.diff(lastAuthDate, 'days').days) < 1;
  }
}
