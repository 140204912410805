import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StorageService } from "./storage.service";

@Injectable()
export class RestService {
  private baseUrl: string = 'http://165.22.222.184:3010/';
  constructor(private http: HttpClient, private storageService: StorageService) {

  }
  private getCommonHeaders(inputHeaders?: any) {
    let authorization = this.storageService.getToken();
    return new HttpHeaders({ ...{ 'Content-type': 'application/json', device: 'Windows Chrome', version: '1.0.0', authorization: authorization ? `Bearer ${authorization}` : '' }, ...inputHeaders || {} });
  }

  public login(payload: any) {
    const url = `${this.baseUrl}auth/login`;
    return this.http.post<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }

  public forgotPassword(payload: any) {
    const url = `${this.baseUrl}auth/forgot-password`;
    return this.http.post<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }

  public getAccounts(payload: any): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}broker-account/filter`;
    return this.http.post<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }

  public getExpiryConfig(scripId: string): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy-call/expiry-config?scripId=${scripId}`;
    return this.http.get<{ status: number, data: any, message: string }>(url, { headers: this.getCommonHeaders() });
  }

  public getContracts(scripId: string): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}option-contracts?scripId=${scripId}`;
    return this.http.get<{ status: number, data: any, message: string }>(url, { headers: this.getCommonHeaders() });
  }

  public submitCall(payload: any): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy-call`;
    return this.http.post<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders({ accountId: payload.accountId }) });
  }

  public fetchStrategies(payload: any): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy/filter`;
    return this.http.post<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }

  public fetchStrategyById(strategyId: string): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy/${strategyId}`;
    return this.http.get<{ status: number, data: any, message: string }>(url, { headers: this.getCommonHeaders() });
  }

  public fetchStrategyCalls(payload: any): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy-call/filter`;
    return this.http.post<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }

  public deleteCall(callId: string): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy-call/${callId}`;
    return this.http.delete<{ status: number, data: any, message: string }>(url, { headers: this.getCommonHeaders() });
  }

  public updateCallRun(payload: any): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy-call/running-status`;
    return this.http.patch<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }

  public updateCall(payload: any): Observable<{ status: number, data: any, message: string }> {
    const url = `${this.baseUrl}strategy-call`;
    return this.http.patch<{ status: number, data: any, message: string }>(url, payload, { headers: this.getCommonHeaders() });
  }
}
